const about = {
  name: 'oisted',
  domain: 'oisted.com',
  mailInfo: 'info@oisted.com',
  mailSales: 'sales@oisted.com',
  mailTailored: 'list@oisted.com',
  service: 'Domain Names',
  detailsBtn: 'Details',
  detailsBtn2: 'Go back',
  title: 'DEMONSTRATION',
  subTitle: 'Catalog of listed and lifted domain names',
  disclaimer: 'All logos shown are for illustration purposes only.',
  disclaimer2: 'The logo shown is for illustration purposes only.',
  imageInfo: 'Click for more information.',
  whatYouGet: 'A domain name.',
  secured: 'Transactions are secured by SSL encryption.',
  about: `We provide uplifting domain names for anyone. Our mission is to raise your brand  by creating the right name.`,
};

export default about;
