const tailored = {
  title: `Something oisted? Let's start!`,
  subTitle: `Are you looking for a return on investment? Let us list and lift your domain name for you.`,
  price: '10 / year',
  service: `We will list your domain name on our website for one year. We'll take care of the details (logo, description, price and etc...) so you can focus on holding your business. oisted is a boutique, which means we're not an inflated marketplace.`,
  process: [
    'Tell us about your domain name.',
    'We will research about your domain name.',
    'If it is a great match for our catalog we will reach out to you.',
    'You will pay for the service once a year. No hidden fees!',
    'We will list and lift your domain name for an entire year.',
  ],
  result: [
    'Be on our catalog of listed and lifted domain names.',
    'Your domain name will point to a special landing page.',
    `The domain name won't be a needle in a haystack!`,
    'Get exposed to potential buyers and search engines.',
    'Get forwarded offers via email.',
    'Pay for each year, WITHOUT selling commissions!',
    'We recommend selling with an escrow service for your safety.',
  ],
};

export default tailored;